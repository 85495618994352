import React from 'react';
import { Autocomplete, styled, TextField, Chip, Box } from '@mui/material';

import { formStyles, autocompleteStyles } from './../../../assets/styles';
import { GDOC_TYPE } from '../../../constants';

const { classes, createStyles } = autocompleteStyles;
const { classes: formClasses, createStyles: createFormStyles } = formStyles;
const StyledTextField = styled(TextField)((props) => ({
  ...createStyles(props),
  ...createFormStyles(props)
}));

const Tags = (props) => {
  const { label, value, source, handleChange, styles, FieldWidth } = props;
  // console.log('[Tags] L16 source ', source);

  return (
    <Box
      sx={{
        width: FieldWidth
      }}
    >
      <div style={styles}>
        <Autocomplete
          multiple
          // freeSolo
          getOptionLabel={(option) => option.Email ? `${option.FirstName} ${option.LastName}` : option.Label}
          options={source}
          value={value}
          onChange={(_, items) => {
            console.log('[items] L32 items ', items);

            handleChange({ target: { value: items.map(
                item => typeof item !== 'string' ? item?.Value : item
              )}
            })
          }}
          renderTags={() => null}
          renderInput={(params) => (
            <StyledTextField
              {...params}
              variant='outlined'
              className={`mb-3 ${formClasses.textbox} ${classes.textField}`}
              label={label}
              placeholder={''}
            />
          )}
        />
        <div className="tags">
          {
            value.map((tag, i) => {
              console.log('[tag] L44 tag ', tag);
              const option = source.find(({ Email }) => Email === tag);

              return (
                <Chip
                  className="tag"
                  variant="outlined"
                  key={i}
                  label={option?.Email ? `${option.FirstName} ${option.LastName}` : tag}
                  onDelete={() => handleChange({ target: { value: value.filter(v => v !== tag) } })}
                />
              );
            })
          }
        </div>
      </div>

    </Box>
  )
};

export default Tags;
