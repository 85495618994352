import {
  setPageTitle,
  setPageButtonsProps,
  setPageFormErros,
  setSidebarToggleMobile,
  setContentSaving,
  setMenu,
  setReportProps,
  setRefreshModal,
  setValidateModal,
  setPageProps,
  setSearch,
  setDocsConfig,
  setDocTypes,
  setDocMenu,
  setDocTreeOptions,
} from './ThemeOptions';

const createDispatches = (dispatch) => ({
  setPageTitle: pageTitle => dispatch(setPageTitle(pageTitle)),
  setPageButtonsProps: props => dispatch(setPageButtonsProps(props)),
  setPageFormErros: errors => dispatch(setPageFormErros(errors)),
  setSidebarToggleMobile: enable => dispatch(setSidebarToggleMobile(enable)),
  setContentSaving: contentSaving => dispatch(setContentSaving(contentSaving)),
  setNavMenu: menu => dispatch(setMenu(menu)),
  setReportProps: reportProps => dispatch(setReportProps(reportProps)),
  setRefreshModal: isShowRefreshModal => dispatch(setRefreshModal(isShowRefreshModal)),
  setValidateModal: isShowValidateModal => dispatch(setValidateModal(isShowValidateModal)),
  setPageProps: props => dispatch(setPageProps(props)),
  setSearch: search => dispatch(setSearch(search)),
  setDocsConfig: docsConfig => dispatch(setDocsConfig(docsConfig)),
  setDocTypes: docTypes => dispatch(setDocTypes(docTypes)),
  setDocMenu: docMenu => dispatch(setDocMenu(docMenu)),
  setDocTreeOptions: docTreeOptions => dispatch(setDocTreeOptions(docTreeOptions)),
});

export {
  createDispatches
}
