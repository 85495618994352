import React, { useCallback, useEffect, useState } from 'react';
import { Box, SvgIcon } from '@mui/material';

import {
  checkAccess,
  getAuthUserReviewer, getFieldParams, getInitialDocValue,
  getItemsByCond
} from './../../../utils/common';
import Progress from './Progress';
import { LoadingButton, ModalConfirm, ModalRemove, RenderField } from './../';
import { AuthService } from './../../../services';
import {
  TITLES,
  FIELD_KEYS,
  STATUS_OPEN,
  STATUS_APPROVED,
  TYPE_APPROVVERS,
  TYPE_SPONSORS,
  TYPE_LEADS,
  DISPLAY_CONTROLLERS,
  ENDPOINTS,
  METHOD_POST,
  METHOD_PUT,
  FIELD_TITLES,
  STEP_ACTION_INIT,
  STEP_ACTION_APPROVE,
  STATUS_COMPLETED,
  STATUS_CLOSED,
  STATUS_ABANDONED, STATUS_ACTIVE
} from './../../../constants';
import { resetGate, notApprove, prepareComment } from './functions';
import { usePendingReviewers } from './../../../hooks'

export default (props) => {
  const {
    setSelectedFieldItems,
    selectedFieldItems,
    selectedItem,
    handleFieldsSave,
    handleFieldsCancel,
    setDisabled,
    docsConfig,
    docType,
    fieldsType,
    setPendingReviewers,
    setActiveStep,
    openGateRequests = [],
    gates = [],
    activeStep,
    items,
    docs
  } = props;

  const [completed, setCompleted] = useState([]);
  const [authUser, setAuthUser] = useState(false);
  const [openOverviewModal, setOpenOverviewModal] = useState(false);
  const [handleSave, setHandleSave] = useState(false);
  const [stepAction, setStepAction] = useState('');
  const [comment, setComment] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [childDocsApproved, setChildDocsApproved] = useState(false);
  const [isAuthSponsor, setIsAuthSponsor] = useState(false);
  const [authUserReviewerType, setAuthUserReviewerType] = useState('');
  const [authUserReviewer, setAuthUserReviewer] = useState({});
  const [docStatus, setDocStatus] = useState('');

  const reviewers = (selectedFieldItems?.[fieldsType]?.[`${selectedItem.type}Reviewers`] || [])
    .sort((reviewerA, reviewerB) => (new Date(reviewerA.createdAt) - new Date(reviewerB.createdAt)));

  const toggleOverviewModal = useCallback(() => {
    setOpenOverviewModal(!openOverviewModal);
  }, [openOverviewModal]);

  const toggleModal = useCallback(() => {
    setOpenModal(!openModal);
  }, [openModal]);

  const onClose = () => {
    toggleOverviewModal();
    setStepAction('');
    setComment('');

    return handleFieldsCancel();
  };

  useEffect(() => {
    const authUser = AuthService.getUser();
    setAuthUser(authUser);
  }, []);

  useEffect(() => {
    if (!selectedItem) return;

    const authUserReviewer = getAuthUserReviewer(selectedFieldItems?.[fieldsType]?.[`${docType}Reviewers`], activeStep) || {};
    const { type: authUserReviewerType } = authUserReviewer;

    setAuthUserReviewerType(authUserReviewerType);
    setAuthUserReviewer(authUserReviewer)

    const { value: docStatus } = getInitialDocValue({ selectedItem, fieldsType: FIELD_KEYS.Fields, suffix: 'Status' }) || {};
    if (docStatus) setDocStatus(docStatus);

    setDisabled(false);
    setPendingReviewers([]);

    if (!gates.length) return;

    const { gate: step = [...gates].shift()?.Value, status } = [...openGateRequests]
      .sort((reviewerA, reviewerB) => (new Date(reviewerB.createdAt) - new Date(reviewerA.createdAt)))
      .shift() || {};

    setActiveStep(step);
    setDisabled(status === STATUS_OPEN ? true : false);

    const completedSteps = gates
      .filter(({ Value }) => (status === STATUS_OPEN ? Value < step : Value <= step))
      .map(({ Value }) => Value);
    setCompleted([...new Set([[...gates].shift()?.Value, ...completedSteps])]);
  }, [selectedItem, docsConfig, selectedFieldItems, authUser]);

  useEffect(() => {
    const histories = (selectedFieldItems?.[fieldsType]?.[`${selectedItem.type}History`] || []);

    if (!handleSave || !histories.length) return;

    setHandleSave(false);
    handleFieldsSave();
    setComment('');
    setStepAction('');
  }, [JSON.stringify((selectedFieldItems?.[fieldsType]?.[`${selectedItem.type}History`] || [])), handleSave]);

  useEffect(() => {
    const relationChildItems = getItemsByCond(
      selectedItem.children || [],
      { Tab: FIELD_TITLES.Overview }
    );

    const childDocsApproved = !relationChildItems.some(
      item => ((item?.[fieldsType] || [])
        .find(({ field }) => field === `${item.type}Reviewers`)?.value || [])
        .some(({ gate, status }) => gate === activeStep && status !== STATUS_APPROVED)
    );

    setChildDocsApproved(childDocsApproved);

  }, [selectedItem, activeStep]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        marginTop: '45px'
      }}
    >
      <Box>
        <Progress
          stepAction={stepAction}
          toggleModal={toggleModal}
          comment={comment}
          docs={docs}
          setHandleSave={setHandleSave}
          authUserReviewerType={authUserReviewerType}
          docsConfig={docsConfig}
          reviewers={reviewers}
          activeStep={activeStep}
          selectedItem={selectedItem}
          items={items}
          fieldsType={fieldsType}
          gates={gates}
          childDocsApproved={childDocsApproved}
          docStatus={docStatus}
          completed={completed}
          authUser={authUser}
          setActiveStep={setActiveStep}
          selectedFieldItems={selectedFieldItems}
          setSelectedFieldItems={setSelectedFieldItems}
          setCompleted={setCompleted}
          setStepAction={setStepAction}
          toggleOverviewModal={toggleOverviewModal}
          docType={docType}
          isAuthSponsor={isAuthSponsor}
          onClose={onClose}
          openOverviewModal={openOverviewModal}
          authUserReviewer={authUserReviewer}
          setComment={setComment}
        />
      </Box>
      <ModalRemove
        title={TITLES.AREYOUSURE}
        subTitle={''}
        confirmWord={selectedItem?.clientName}
        openModal={openModal}
        toggleModal={
          () => {
            setSelectedFieldItems({
              ...selectedFieldItems,
              [FIELD_KEYS.Fields]: {
                ...selectedFieldItems[FIELD_KEYS.Fields],
                [`${selectedItem.type}Status`]: STATUS_ACTIVE,
                Status: STATUS_ACTIVE
              },
              notGrouped: {
                Status: STATUS_ACTIVE
              }
            });
            toggleModal()
          }
        }
        handleDelete={() => {
          console.log('[handleDelete] L195 ', selectedItem);
          handleFieldsSave()
          toggleModal();
        }}
        deleteTitle={'Archive'}
      />
    </Box>
  );
}
