import React, { useCallback, useRef, useState, useMemo } from "react";
import {
  Box,
  ClickAwayListener,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  SvgIcon
} from "@mui/material";
import { AspectRatio as AspectRatioIcon } from '@mui/icons-material';

import { ReactComponent as DotsHorizontalIcon } from '../../../assets/images/tabs/dots-horizontal.svg'
import { createDispatches } from "../../../reducers/functions";
import { useDispatch, useSelector } from "react-redux";

const composeMenu = ({ anchorChartRef, title }) => [
  {
    title: 'Print',
    onClick: () => {
      anchorChartRef.current.instance.print();
    }
  },
  {
    title: 'Export PNG file',
    onClick: () => {
      anchorChartRef.current.instance.exportTo(title, 'png');
    }
  },
  {
    title: 'Export JPEG file',
    onClick: () => {
      anchorChartRef.current.instance.exportTo(title, 'jpeg');
    }
  },
  {
    title: 'Export PDF file',
    onClick: () => {
      anchorChartRef.current.instance.exportTo(title, 'pdf');
    }
  },
  {
    title: 'Export SVG file',
    onClick: () => {
      anchorChartRef.current.instance.exportTo(title, 'svg');
    }
  }
];
export const Dots = (props) => {
  const { anchorChartRef, title, reportId } = props;
  const { setReportProps } = createDispatches(useDispatch());
  const { reportProps } = useSelector(state => state.ThemeOptions);
  const [openActions, setOpenActions] = useState(false);
  const anchorRef = useRef(null);
  const menuItems = useMemo(
    () => composeMenu({ anchorChartRef, title }),
    [anchorChartRef, title]
  );

  const openReport = useCallback(() => {
    setReportProps({
      reportId,
      openModal: true
    });
  }, [reportId]);

  const handleToggleActions = useCallback(() => {
    setOpenActions(!openActions);
  }, [openActions]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        alignContent: 'center',
        justifyContent: 'flex-end',
        marginTop: '-42px'
      }}
    >
      {
        !reportProps?.openModal && (
          <IconButton
            onClick={openReport}
            size="large"
          >
            <AspectRatioIcon />
          </IconButton>
        )
      }
      <IconButton
        ref={anchorRef}
        aria-controls={openActions ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggleActions}
        size="large"
      >
        <SvgIcon
          component={DotsHorizontalIcon}
        />
      </IconButton>
      <Popper open={openActions} anchorEl={anchorRef.current} role={undefined} transition
              disablePortal
              placement={'bottom-end'} style={{ zIndex: 10 }}
              modifiers={[{ name: 'offset', options: { offset: -10 } }]}>
        {({ TransitionProps, placement }) => (<Grow
          {...TransitionProps}
          style={{ transformOrigin: placement === 'bottom' ? 'left top' : 'left bottom' }}
        >
          <Paper>
            <ClickAwayListener onClickAway={() => {
              setOpenActions(false);
            }}>
              <MenuList autoFocusItem={openActions} id="menu-list-grow">
                {
                  menuItems.map(({ title, onClick }) => (
                    <MenuItem
                      key={title}
                      onClick={onClick}
                    >
                      { title }
                    </MenuItem>
                  ))
                }
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>)}
      </Popper>
    </Box>
  )
}

export default Dots;
