import React from 'react';
import { Box, Dialog as MuiDialog, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

import { dialogStyles } from './../../../assets/styles';

const { createStyles } = dialogStyles;
const Dialog = styled(MuiDialog)(createStyles);

const ModalComponent = props  => {
  const {
    onClose = () => {},
    openModal,
    children
  } = props;

  return (
    <Dialog
      PaperProps={{
        sx: {
          minWidth: "90vw",
          minHeight: "90vh",
        }
      }}
      open={openModal}
      onClose={onClose}
    >
      <Box>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            alignContent: 'center',
            justifyContent: 'flex-end',
            padding: '10px 20px 0px 20px'
          }}
        >
          <IconButton
            aria-label="close"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
          {
            children
          }
      </Box>
    </Dialog>
  );
};

export default ModalComponent;
