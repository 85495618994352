import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  ACTIONS, CONTROL_DOC_DDOC,
  FIELD_KEYS,
  FIELD_TITLES,
  GDOC_TYPE, KDOC_TYPE, MDOC_TYPE, STATUS_ABANDONED,
  STATUS_CLOSED,
  STATUS_OPEN,
  TYPE_SPONSORS
} from './../constants';
import { createDispatches } from './../reducers/functions';
import { useDocProgress, useDocChipsProgress } from './';
import {
  composeGridValues,
  findDoc,
  findItem,
  getItemsByCond,
  getParentDocId,
  getParentType,
  getTotalByGridValues,
  composeInitGateIfNeed,
  modifyItems,
  prepareDbDoc,
  prepareTree, getFieldParams
} from './../utils/common';
import {
  getDefaultFields,
  changeLockDocument
} from './../utils/docs';
import { Request } from './../services';

const useDocumentProps = ({
  authUser,
  configs,
  docsConfig,
  controlDocsConfig,
  selectedItem,
  selected,
  setItems,
  items,
  setDocs,
  docs,
  setSelectedFieldItems,
  selectedFieldItems,
  setOpenGridModal,
  openGridModal,
  setOpenRemoveModal,
  openRemoveModal,
  setRefreshDocument,
  setValidated,
  validated,
  setDisabled,
  setPrevSelectedItem = () => {},
  parentId,
  initTree = () => {},
  setSelected = () => {},
  setParents = () => {},
  setParentId = () => {},
  setErrors = () => {},
}) => {

  const { setContentSaving } = createDispatches(useDispatch());
  const [kDocAggregationMethod, setKDocAggregationMethod] = useState();

  const progress = useDocProgress(selectedItem);
  const progressChips = useDocChipsProgress(selectedItem, docsConfig);

  const isShowAggregations = useMemo(() => (selectedItem?.[FIELD_KEYS.Aggregations] || [])
    .some(({ value }) => !!value && Object.keys(value).length), [selectedItem?.[FIELD_KEYS.Aggregations]]);
  const isShowCalculations = useMemo(() => (selectedItem?.[FIELD_KEYS.Calculations] || [])
    .some(({ value }) => !!value && Object.keys(value).length), [selectedItem?.[FIELD_KEYS.Calculations]]);

  const { type: docType } = selectedItem || {};

  const Tabs = useMemo(() => (docsConfig?.[docType]?.Tabs || []).filter(({ Name: fieldsType }) => (
    ![FIELD_KEYS.Aggregations, FIELD_KEYS.Calculations].includes(fieldsType) && !!(selectedItem?.[fieldsType] || []).length ||
    fieldsType === FIELD_KEYS.Aggregations && isShowAggregations ||
    fieldsType === FIELD_KEYS.Calculations && isShowCalculations
  )), [docsConfig?.[docType]?.Tabs, selectedItem]);
  const defaultValues = useMemo(
    () => findDoc({ id: selectedItem?.nodeId, docType, docs }) || {},
    [selectedItem?.nodeId, docType, JSON.stringify(docs)]
  );

  const toggleRemoveModal = useCallback(() => {
    setOpenRemoveModal(!openRemoveModal);
  }, [openRemoveModal]);

  const handleGridChange = useCallback((year, index, val, prefix) => {
    if (!selectedFieldItems?.[FIELD_KEYS.TimeFields]?.[`${docType}GridValues${prefix}`]) return;

    const nextDocGridValues = composeGridValues({
      gridValues: selectedFieldItems?.[FIELD_KEYS.TimeFields]?.[`${docType}GridValues${prefix}`],
      year,
      index,
      val
    });
    const total = getTotalByGridValues(nextDocGridValues);

    setSelectedFieldItems({
      ...selectedFieldItems,
      [FIELD_KEYS.TimeFields]: {
        ...selectedFieldItems[FIELD_KEYS.TimeFields],
        [`${docType}GridValues${prefix}`]: nextDocGridValues,
        [`${docType}Total${prefix}`]: total
      },
      [FIELD_KEYS.Fields]: {
        ...selectedFieldItems[FIELD_KEYS.Fields],
        [`${docType}Value`]: total,
      }
    });
  }, [selectedFieldItems?.[FIELD_KEYS.TimeFields]]);

  const toggleGridModal = useCallback(() => {
    setOpenGridModal(!openGridModal);
  }, [openGridModal]);

  const reloadDocument = useCallback(() => {
    setRefreshDocument(true);
    Request.getDoc(selectedItem.type, selectedItem.nodeId)
      .then((doc) => {
        const nextDocs = {
          ...docs,
          [docType]: [
            ...docs[docType].filter((doc) => doc[`${docType}Id`] !== selectedItem.nodeId),
            doc
          ]
        };
        setDocs(nextDocs);

        const nextItems = prepareTree({ docsConfig, docs: nextDocs });
        setItems(nextItems);

        const item = findItem(nextItems, selectedItem.nodeId);
        const defaultFieldsData = getDefaultFields(item, docsConfig);
        setSelectedFieldItems(defaultFieldsData);

        setRefreshDocument(false);
        setValidated(false);
        setDisabled(doc?.LockInfo?.Locked);
      })
      .catch(() => setRefreshDocument(false));
  }, [items, docs, docsConfig, docType, selectedItem]);

  const validateAccessDocument = useCallback(() => {
    if (
      !selectedItem ||
      validated ||
      selectedItem?.notGrouped?.LockInfo?.Locked
    ) return;

    const expirationDateOffset = configs?.baseConfig?.CommonSettings?.DocLockDuration || 0;

    const setExpirationDate = () => {
      const date = new Date();
      date.setMinutes(date.getMinutes() + Number(expirationDateOffset));

      return date;
    };

    setValidated(true);
    Request.getDoc(selectedItem.type, selectedItem.nodeId)
      .then((doc) => {
        const currentDate = new Date();
        const expirationDate = doc.LockInfo?.ExpirationDate ? new Date(doc.LockInfo?.ExpirationDate) : currentDate;

        const isDocLockExpired = (currentDate - expirationDate) >= 0;

        const { validate = () => {} } = [
          {
            condition: doc.LockInfo?.Locked && doc.LockInfo?.UserId !== authUser.UserId && !isDocLockExpired,
            validate: () => {
              const nextItem = {
                ...selectedItem,
                notGrouped: {
                  ...selectedItem.notGrouped,
                  LockInfo: doc.LockInfo
                }
              };
              setPrevSelectedItem(nextItem);
              setItems(modifyItems(items, nextItem));
              const defaultFieldsData = getDefaultFields(nextItem, docsConfig);
              setSelectedFieldItems(defaultFieldsData);
              setDisabled(true);
            }
          },
          {
            condition: doc.LockInfo?.Locked && doc.LockInfo?.UserId === authUser.UserId,
            validate: () => {
              const nextItem = {
                ...selectedItem,
                notGrouped: {
                  ...selectedItem.notGrouped,
                  LockInfo: {
                    Locked: true,
                    UserId: authUser.UserId,
                    Email: authUser.Email,
                    FirstName: authUser.FirstName,
                    LastName: authUser.LastName,
                    ExpirationDate: setExpirationDate()
                  }
                }
              };
              setPrevSelectedItem(nextItem);
            }
          },
          {
            condition: !doc.LockInfo?.Locked || (doc.LockInfo?.Locked && isDocLockExpired),
            validate: () => {
              const nextItem = {
                ...selectedItem,
                notGrouped: {
                  ...selectedItem.notGrouped,
                  LockInfo: {
                    Locked: true,
                    UserId: authUser.UserId,
                    Email: authUser.Email,
                    FirstName: authUser.FirstName,
                    LastName: authUser.LastName,
                    ExpirationDate: setExpirationDate()
                  }
                }
              };
              changeLockDocument({ selectedItem: nextItem, parentId, docsConfig});
              setPrevSelectedItem(nextItem);
            }
          }
        ].find(({ condition }) => condition) || {};

        validate();
      });
  }, [selectedItem, validated, parentId, JSON.stringify(items), authUser, configs]);

  const handleFieldsSave =
    useCallback( () => {
      setContentSaving(true);
      setErrors([]);

      const nextSelectedItem = {
        ...selectedItem,
        notGrouped: {
          ...selectedItem.notGrouped,
          ...(selectedFieldItems.notGrouped || {}),
          LockInfo: {}
        }
      };
      setPrevSelectedItem(nextSelectedItem);
      const dbDoc = prepareDbDoc({
        selectedItem: nextSelectedItem,
        selectedFieldItems,
        parentId,
        docType,
        Tabs: docsConfig?.[docType]?.Tabs
      });
      console.log('[handleFieldsSave] dbDoc: ', dbDoc);

      const { method, args } = [
        {
          condition: selectedItem.isNew,
          method: 'createDoc',
          args: [docType, { ...dbDoc, [`${docType}Id`]: selectedItem.nodeId }]
        },
        {
          condition: !selectedItem.isNew,
          method: 'updateDoc',
          args: [docType, selectedItem.nodeId, dbDoc]
        }
      ].find(({ condition }) => condition);

      Request[method](...args)
        .then(() => {
          const { value: Status } = (selectedItem?.Fields || []).find(({ field }) => field === 'Status') || {};
          if (docType === GDOC_TYPE) {
            if (!selectedItem.isNew && dbDoc.Status !== Status) {
              Request.patchDocs({ Status: dbDoc.Status }, { [`${GDOC_TYPE}Id`]: dbDoc[`${GDOC_TYPE}Id`] })
            }
          }

          setValidated(false);
          setOpenGridModal(false);

          const isUpdateChildren = (dbDoc[`${docType}OpenGateRequests`] || []).length ||
            dbDoc[`${docType}Scaling`] !== (selectedItem[FIELD_KEYS.StandardDocFields] || []).find(({ field }) => field === `${docType}Scaling`)?.value;

          if (dbDoc.Status !== Status && (selectedItem.children || []).length) {
            Request.patchDocs({ Status: dbDoc.Status }, { [`${selectedItem.type}Id`]: selectedItem.nodeId })
          }

          if (!isUpdateChildren) return initTree();

          const relationChildItems = getItemsByCond(
            selectedItem.children || [],
            { Tab: FIELD_TITLES.Overview }
          );
          const promises = relationChildItems.map(item => {
            const parentId = getParentDocId({ id: item.nodeId, docType: item.type, docs });
            const dbChildDoc = prepareDbDoc({ selectedItem: item, selectedFieldItems: {}, parentId, docType: item.type, Tabs: docsConfig?.[item.type]?.Tabs });

            const openRequest = dbDoc[`${docType}OpenGateRequests`].find(({ status }) => status === STATUS_OPEN);
            const isUpdateOpenGateRequests = openRequest &&
              !(dbChildDoc[`${item.type}OpenGateRequests`] || [])
                .some(({ gate, status }) => gate === openRequest.gate);
            const isUpdateScaling = dbDoc[`${docType}Scaling`] !== dbChildDoc[`${item.type}Scaling`];

            if (!isUpdateOpenGateRequests && !isUpdateScaling) return Promise.resolve();

            const sponsorReviewers = dbDoc[`${docType}Reviewers`].filter(({ type }) => type === TYPE_SPONSORS);
            const gates = sponsorReviewers.map(({ gate }) => gate);
            const nextReviewers = (!isUpdateOpenGateRequests || (dbChildDoc[`${item.type}Reviewers`] || []).some(({ type }) => type === TYPE_SPONSORS))
              ? dbChildDoc[`${item.type}Reviewers`]
              : [
                ...(dbChildDoc[`${item.type}Reviewers`] || []),
                ...sponsorReviewers
              ]
            ;
            const nextOpenGateRequest = {
              ...openRequest,
              status: nextReviewers.filter(({ gate }) => openRequest.gate === gate).length
                ? STATUS_OPEN
                : STATUS_CLOSED
            };
            const nextOpenGateRequests = isUpdateOpenGateRequests
              ? [
                  ...(dbChildDoc[`${item.type}OpenGateRequests`] || []),
                  ...composeInitGateIfNeed(nextOpenGateRequest, gates)
                ]
              : dbChildDoc[`${item.type}OpenGateRequests`]
            ;

            return Request.updateDoc(
              item.type,
              item.nodeId,
              {
                ...dbChildDoc,
                [`${item.type}Scaling`]: dbDoc[`${docType}Scaling`],
                [`${item.type}OpenGateRequests`]: nextOpenGateRequests,
                [`${item.type}Reviewers`]: nextReviewers
              }
            );
          });

          return Promise.all(promises).catch(console.log).then(initTree);
        })
        .catch((error) => {
          setErrors([error.message]);
          setTimeout(() => {
            setErrors([]);
          }, 3000)
          setValidated(false);
        })
        .then(() => setContentSaving(false))
      ;
      // setItems(modifyItems(items, nextSelectedItem));
    }, [selectedItem, selectedFieldItems, parentId, docs]);

  const setDefaultFieldsData = useCallback(() => {
    if (!selectedItem) return;
    setDisabled(false);

    const defaultFieldsData = getDefaultFields(selectedItem, docsConfig);
    setSelectedFieldItems(defaultFieldsData);

    return defaultFieldsData;
  }, [JSON.stringify(selectedItem), docsConfig]);

  const handleFieldsCancel = useCallback( () => {
    const tree = prepareTree({ docsConfig, docs });
    setItems(tree);

    if (!Object.keys(docs).some(docType => docs[docType].map(({ [`${docType}Id`]: id }) => id).includes(selectedItem.nodeId)))
      setSelected([...tree].shift()?.nodeId);

    const parentType = getParentType(docType);
    if (parentType) {
      setParents(docs[parentType].map(
        (parentDoc) => Object.keys(parentDoc).reduce((result, key) => ({ ...result, [key.replace(parentType, '')]: parentDoc[key] }), {}))
      );
      setParentId(getParentDocId({ id: selectedItem.nodeId, docType, docs }));
    }

    return setDefaultFieldsData();
  }, [selectedItem, docs, docsConfig]);

  const createRemoveFunc = useCallback((id, type) => () => () => {
    setContentSaving(true);
    toggleRemoveModal();

    return Request.removeDoc(type, id)
      .then(() => initTree({ action: ACTIONS.Remove }))
      .catch(console.log)
      .then(() => setContentSaving(false))
      ;
  }, []);

  useEffect(() => {
    if (selectedItem?.type !== KDOC_TYPE) {
      return setKDocAggregationMethod();
    }



    const id = (selectedItem[FIELD_KEYS.Fields] || []).find(({ field }) => field === `${CONTROL_DOC_DDOC}Id`)?.value;
    console.log('[id] L398 id ', id);

    Request.getControlDocs({
      docType: CONTROL_DOC_DDOC,
      [`${CONTROL_DOC_DDOC}Id`]: id,
      'CreatedAt[$ne]': Math.floor(Date.now()/1000)
    }).then(([dDoc = {}]) => setKDocAggregationMethod(dDoc?.dDocAgg))
      .catch((error) => {
      console.log('[setKDocAggregationMethod] L407 error ', error);

      return {};
    })

  }, [selectedItem]);

  return {
    selectedItem,
    defaultValues,
    docType,
    Tabs,
    handleGridChange,
    toggleGridModal,
    toggleRemoveModal,
    reloadDocument,
    validateAccessDocument,
    handleFieldsSave,
    setDefaultFieldsData,
    handleFieldsCancel,
    createRemoveFunc,
    progress,
    progressChips,
    kDocAggregationMethod
  };
};

export default useDocumentProps;
