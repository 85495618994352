import {
  FIELD_KEYS,
  FIELD_TITLES, STATUS_NOT_APPROVED,
  STATUS_PENDING,
  STEP_ACTION_INIT, STEP_ACTION_NOT_APPROVED,
  STEP_ACTION_RESET_GATE, TYPE_APPROVVERS, TYPE_LEADS, TYPE_SPONSORS
} from '../../../constants';
import { getInitialDocValue, getItemsByCond, getParentDocId, prepareDbDoc } from '../../../utils/common';
import { Request } from '../../../services';

export const prepareComment = ({ authUser, activeStep, stepAction, comment, authUserReviewerType }) => {
  const reviewerType = {
    [TYPE_APPROVVERS]: 'Approver',
    [TYPE_SPONSORS]: 'Sponsor',
    [TYPE_LEADS]: 'Leader'
  }[authUserReviewerType];

  return {
    reviewerType,
    email: authUser.Email,
    action: stepAction,
    step: activeStep,
    message: comment,
    createdAt: new Date()
  }
};

export const resetGate = (props) => {
  const {
    stepAction,
    activeStep,
    selectedItem,
    selectedFieldItems,
    fieldsType,
    docType,
    setSelectedFieldItems,
    authUser,
    comment,
    setHandleSave,
    docs,
    authUserReviewerType,
    docsConfig
  } = props;

  if (stepAction === STEP_ACTION_INIT) return;

  const getUpdatedReviewers = (reviewers) =>
    [
      ...(reviewers || selectedFieldItems?.[fieldsType]?.[`${docType}Reviewers`] || [])
        .map(reviewer => {
          if(reviewer.gate === activeStep) return { ...reviewer, status: STATUS_PENDING, updatedAt: new Date() };
          else return reviewer;
        })
    ];

  const getUpdatedOpenGateRequests = (requests) => {
    const { value: initialOpenGateRequests = [] } = getInitialDocValue({ selectedItem, fieldsType, suffix: 'OpenGateRequests' });

    return [
      ...(requests || initialOpenGateRequests).filter(({ gate, status }) => !(gate === activeStep) )
    ];
  };

  setSelectedFieldItems({
    ...selectedFieldItems,
    [FIELD_KEYS.Approvers]: {
      ...selectedFieldItems[FIELD_KEYS.Approvers],
      [`${selectedItem.type}Reviewers`]: getUpdatedReviewers()
    },
    [fieldsType]: {
      ...selectedFieldItems[fieldsType],
      [`${selectedItem.type}Reviewers`]: getUpdatedReviewers(),
      [`${selectedItem.type}OpenGateRequests`]: getUpdatedOpenGateRequests(),
      [`${selectedItem.type}History`]: [
        ...(selectedFieldItems[fieldsType][`${selectedItem.type}History`] || []),
        prepareComment({ authUser, activeStep, stepAction: STEP_ACTION_RESET_GATE, comment, authUserReviewerType })
      ]
    }
  });

  const relationChildItems = getItemsByCond(
    selectedItem.children || [],
    { Tab: FIELD_TITLES.Overview }
  );
  const promises = relationChildItems.map(item => {
    const parentId = getParentDocId({ id: item.nodeId, docType: item.type, docs });
    const dbChildDoc = prepareDbDoc({ selectedItem: item, selectedFieldItems: {}, parentId, docType: item.type, Tabs: docsConfig?.[item.type]?.Tabs });

    return Request.updateDoc(
      item.type,
      item.nodeId,
      {
        ...dbChildDoc,
        [`${item.type}OpenGateRequests`]: getUpdatedOpenGateRequests(dbChildDoc[`${item.type}OpenGateRequests`]),
        [`${item.type}Reviewers`]: getUpdatedReviewers(dbChildDoc[`${item.type}Reviewers`])
      }
    );
  });

  Promise.all(promises).catch(console.log);

  setHandleSave(true);
};

export const notApprove = (props) => {
  const {
    selectedItem,
    selectedFieldItems,
    fieldsType,
    setSelectedFieldItems,
    setHandleSave,
    authUser,
    activeStep,
    comment,
    authUserReviewerType,
    authUserReviewer,
  } = props;

  const { value: initReviewers = [] } = getInitialDocValue({ selectedItem, fieldsType, suffix: 'Reviewers' }) || {};
  const { value: initOpenGateRequests = [] } = getInitialDocValue({ selectedItem, fieldsType, suffix: 'OpenGateRequests' }) || {};

  const nextReviewers = ![TYPE_APPROVVERS].includes(authUserReviewerType)
    ? initReviewers
    : [
      ...initReviewers.filter(({ id, gate }) => id !== authUserReviewer.id || authUserReviewer.gate !== gate),
      {
        ...initReviewers.find(({ id, gate }) => id === authUserReviewer.id && authUserReviewer.gate === gate),
        status: STATUS_NOT_APPROVED,
        updatedAt: new Date()
      }
    ];

  setSelectedFieldItems({
    ...selectedFieldItems,
    [FIELD_KEYS.Approvers]: {
      ...selectedFieldItems[FIELD_KEYS.Approvers],
      [`${selectedItem.type}Reviewers`]: nextReviewers
    },
    [FIELD_KEYS.Fields]: {
      ...selectedFieldItems[FIELD_KEYS.Fields],
    ...(
        [TYPE_APPROVVERS].includes(authUserReviewerType)
          ? {}
          : { Status: STATUS_NOT_APPROVED }
      ),
    },
    [fieldsType]: {
      ...selectedItem[fieldsType],
      [`${selectedItem.type}Reviewers`]: nextReviewers,
      [`${selectedItem.type}OpenGateRequests`]: initOpenGateRequests,
      [`${selectedItem.type}History`]: [
        ...(selectedFieldItems[fieldsType][`${selectedItem.type}History`] || []),
        prepareComment({ authUser, activeStep, stepAction: STEP_ACTION_NOT_APPROVED, comment, authUserReviewerType })
      ]
    }
  });

  setHandleSave(true);
};

export default {
  resetGate,
  notApprove
};
