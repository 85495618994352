import React, { useCallback, useRef, useState } from 'react';
import {
  ClickAwayListener,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  useTheme,
  Box, SvgIcon, Tooltip
} from '@mui/material';
import { tooltipClasses } from "@mui/material/Tooltip";
import { Dehaze as DehazeIcon } from '@mui/icons-material';
import { styled } from "@mui/material/styles";

import { NavButtons, RenderField } from '../index';
import { DISPLAY_CONTROLLERS, FIELD_KEYS } from '../../../constants';
import { ReactComponent as InfoIcon } from '../../../assets/images/tabs/info.svg'
import { ReactComponent as DotsHorizontalIcon } from '../../../assets/images/tabs/dots-horizontal.svg'

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }}/>))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const Menu = (props) => {
  const {
    classes = 'w-100',
    styles = {},
    title,
    menuItems = [],
    Icon = DehazeIcon,
    selectedDoc,
    docsConfig = {}
  } = props;
  const theme = useTheme();

  const [openActions, setOpenActions] = useState(false);
  const anchorRef = useRef(null);

  const handleToggleActions = useCallback(() => {
    setOpenActions(!openActions);
  }, [openActions]);

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gap: 1,
        gridTemplateRows: 'auto',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          alignContent: 'center',
          justifyContent: 'flex-start',
        }}
      >
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(1, 1fr)',
            gridTemplateRows: 'auto',
            gap: '10px',
            padding: '10px 2px'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              alignContent: 'center',
              justifyContent: 'flex-start',
              minHeight: '31px',
            }}
          >
          </Box>
          <Box
            className="font-weight-bold font-size-lg"
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              alignContent: 'center',
              justifyContent: 'flex-start',
              lineHeight: '20px'
            }}
          >
            {title || ''}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              alignContent: 'center',
              justifyContent: 'flex-start',
              width: '100%',
              minWidth: '250px',
              minHeight: '10px',
            }}
          >
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          alignContent: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(1, 1fr)',
            gridTemplateRows: 'auto',
          }}
        >
          <Box>
            <LightTooltip placement="bottom-start" title={<RenderField
              item={{
                field: `${selectedDoc?.type}Help`,
                displayController: DISPLAY_CONTROLLERS.Help,
              }}
              fieldsType={FIELD_KEYS.RichTextFields}
              docsConfig={docsConfig}
              docType={selectedDoc?.type}
            />}>
              <IconButton
                aria-haspopup="true"
                size="large"
              >
                <SvgIcon
                  component={InfoIcon}
                />
              </IconButton>
            </LightTooltip>
            <IconButton
              className={!menuItems.length ? 'opacity-none' : ''}
              ref={anchorRef}
              aria-controls={openActions ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              onClick={handleToggleActions}
              size="large"
            >
              <SvgIcon
                component={DotsHorizontalIcon}
              />
            </IconButton>
            <Popper open={openActions} anchorEl={anchorRef.current} role={undefined} transition disablePortal
                    placement={'bottom-end'} style={{ zIndex: 10 }}
                    modifiers={[{ name: 'offset', options: { offset: -10 } }]}>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{ transformOrigin: placement === 'bottom' ? 'left top' : 'left bottom' }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={() => {
                      setOpenActions(false)
                    }}>
                      <MenuList autoFocusItem={openActions} id="menu-list-grow">
                        {
                          menuItems.map(({ onClick, title }) => (
                            <MenuItem
                              key={title}
                              onClick={onClick}
                            >
                              {title}
                            </MenuItem>
                          ))
                        }
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Box>
          <NavButtons />
        </Box>
      </Box>
    </Box>
  )
    ;
};

export default Menu
