import React from 'react';
import {
  Chart,
  Series,
  CommonSeriesSettings,
  SeriesTemplate,
  Legend,
  ValueAxis,
  Title,
  Export,
  Tooltip,
  Border,
  ArgumentAxis,
  Grid,
} from 'devextreme-react/chart';
import PieChart, {
  Series as PieSeries,
  Label as PieLabel,
  Connector as PieConnector,
  Tooltip as PieTooltip,
  Export as PieExport,
} from 'devextreme-react/pie-chart';

import {
  COMPONENT_TYPE_PIE,
  COMPONENT_TYPE_CHART,
  COMPONENT_TYPE_POLAR,
  COMPONENT_TYPE_GANTT,
  COMPONENT_TYPE_FUNNEL
} from '../../../constants';

import PolarChart, {
  Series as PolarSeries,
  Export as PolarExport,
} from 'devextreme-react/polar-chart';
import { useTheme } from "@mui/material";
import {
  Gantt,
  Tasks,
  Dependencies,
  Resources,
  ResourceAssignments,
  Column,
  Toolbar,
  Item,
} from 'devextreme-react/gantt';
import Funnel, {
  Export as FunnelExport,
  Tooltip as FunnelTooltip,
  Item as FunnelItem,
  Border as FunnelBorder,
  Label,
  Size,
} from 'devextreme-react/funnel';

const DevExtremeChart = (props) => {
  const theme = useTheme();
  const {
    anchorChartRef,
    color,
    componentType = COMPONENT_TYPE_CHART,
    id = props.report?.ReportId ?? 'chart',
    title = 'Population: Age Structure',
    yAxisTitle = 'Populations, millions',
    argumentField = 'state',
    valueField = 'area',
    type = 'stackedBar',
    barPadding = 0,
    series = [],
    seriesColor = theme.palette.primary.main,
    dataSource = [],
    legend = {visible:false},
    rotated = false,
    borderProperties = {
      visible: false
    },
    exportProperties = {
      enabled: true
    },
    labelProperties = {
      visible: true,
      position: "inside",
      radialOffset: 83,
      backgroundColor: "transparent",
      showForZeroValues: false
    },
    tooltipProperties = {
      enabled: true
    },
    legendProperties = {
      position: 'inside',
      columnCount: 2,
      customizeItems: items => items,
      horizontalAlignment: 'right'
    },
    nameField,
    customizeSeries,
    height = props.report?.Height ?? '100%',
    palette = 'Bright',
    pointClickHandler = () => {},
    legendClickHandler = () => {},
    docs=[],
    dependencies=[],
    managementAreas=[],
    managementAreasAssignments=[],
    contextMenu = { enabled: false },
    taskListWidth=500,
    titleWidth = 300,
    argumentAxis,
    customText = ({ item }) => `<span style="font-size: 1rem;">${item.argument}</span>`,
    customizePoint,
  } = props;

  // console.log('props: ', JSON.stringify(props, null, 4))

  const {
    render = () => (
      <>
        <Chart
          id={id}
          ref={anchorChartRef}
          style={{ height }}
          title={title}
          stickyHovering={false}
          dataSource={dataSource}
          rotated={rotated}
          customizePoint={customizePoint}
        >
          {
            customizeSeries && nameField && (
              <SeriesTemplate
                nameField={nameField}
                customizeSeries={customizeSeries}
              />
            )
          }
          <CommonSeriesSettings
            color={color}
            argumentField={argumentField}
            type={type}
            // hoverMode="includePoints"
            barPadding={barPadding}
          />
          {
            series.map(({ valueField, name, stack, ...props },index) => (
              <Series
                {...props}
                key={index}
                valueField={valueField}
                name={name}
                stack={stack}
              />
            ))
          }
          <ValueAxis
            allowDecimals={false}
          >
            <Title text={yAxisTitle} />
          </ValueAxis>
          {
            argumentAxis && <ArgumentAxis {...argumentAxis} />
          }
          {
            legendProperties && (
              <Legend {...legendProperties}>
                <Border {...borderProperties} />
              </Legend>
            )
          }
          <Export {...exportProperties} />
          <Tooltip {...tooltipProperties} />
        </Chart>
      </>
    )
  } = [
    {
      condition: componentType === COMPONENT_TYPE_PIE,
      render: () => (
        <PieChart
          // id={id}
          ref={anchorChartRef}
          dataSource={dataSource}
          palette={palette}
          title={title}
          type={type}
          onPointClick={pointClickHandler}
          onLegendClick={legendClickHandler}
          customizePoint={customizePoint}
        >
          <PieSeries
            argumentField={argumentField}
            valueField={valueField}
          >
            <PieLabel {...labelProperties}>
              <PieConnector visible={true} width={1} />
            </PieLabel>
          </PieSeries>
          <PieTooltip
            paddingLeftRight={12}
            paddingTopBottom={10}
          />
          {/*<PieSize width={300} />*/}
          <PieExport {...exportProperties} />
        </PieChart>
      )
    },
    {
      condition: componentType === COMPONENT_TYPE_POLAR,
      render: () => (
        <PolarChart
          // id={id}
          ref={anchorChartRef}
          dataSource={dataSource}
          palette={palette}
          title={title}
          onPointClick={pointClickHandler}
          onLegendClick={legendClickHandler}
          tooltip={tooltipProperties}
          legend={legend}
        >
          <PolarSeries
            type="bar"
            barPadding={barPadding}
            color={seriesColor}
            argumentField={argumentField}
            valueField={valueField}

          >
          </PolarSeries>
          <PolarExport {...exportProperties} />
        </PolarChart>
      )
    },
    {
      condition: componentType === COMPONENT_TYPE_GANTT,
      render: () => (
        <Gantt
          taskListWidth={taskListWidth}
          scaleType="months"
          height={height}
          rootValue={0}
          contextMenu={contextMenu}
        >

          <Tasks dataSource={docs} />
          <Dependencies dataSource={dependencies} />
          <Resources dataSource={managementAreas} />
          <ResourceAssignments dataSource={managementAreasAssignments} />

          <Toolbar>
            <Item name="collapseAll" />
            <Item name="expandAll" />
            <Item name="separator" />
            <Item name="zoomIn" />
            <Item name="zoomOut" />
          </Toolbar>

          <Column dataField="title" caption="Documents" width={titleWidth} />
          <Column dataField="start" caption="Start Date" />
          <Column dataField="end" caption="End Date" />

        </Gantt>
      )
    },
    {
      condition: componentType === COMPONENT_TYPE_FUNNEL,
      render: () => (
        <Funnel
          ref={anchorChartRef}
          dataSource={dataSource}
          palette="Soft Pastel"
          argumentField="label"
          valueField="value"
        >
          <Size
            height={450}
            width={600}
          />
          <FunnelExport enabled={true} />
          <FunnelTooltip
            enabled={true}
            format="fixedPoint"
            contentRender ={({ item }) => {
              return (
                <div style={{ padding: 10 }}>
                  <span>{item.argument}</span>
                </div>
              )
            }}
          />
          <FunnelItem width={600}>
            <FunnelBorder visible={true} />
          </FunnelItem>
          <Label
            visible={true}
            position="inside"
            backgroundColor="none"
          />
        </Funnel>
      )
    },
  ].find(({ condition }) => condition) || {}

  return render();
};

export default DevExtremeChart;
