import React from 'react';
import { useSelector } from 'react-redux';
import { Table as MuiTable } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  PRDOC_TYPE,
  TDOC_TYPE,
  RDOC_TYPE,
  UOM_FORMATS,
  CALCULATIONS_GROUP_ESTIMATED,
  DISPLAY_CONTROLLERS,
  CALCULATIONS_GROUPS
} from './../../../constants';
import { getFieldParams, round, uomFormat } from './../../../utils/common';
import { classes, createStyles } from './../../../assets/styles/tableResponsive';

const Table = styled(MuiTable, {
  shouldForwardProp: (prop) => !['$columns', '$breakpoint', '$additionalStyles'].includes(prop)
})(createStyles);

const getGroupByFieldName = (FieldName = '') =>
  CALCULATIONS_GROUPS.find(calculationsGroupType => FieldName.includes(calculationsGroupType))

const composeFieldName = (fieldName, docType) => [
  {
    condition: fieldName === `${PRDOC_TYPE}Hours`,
    fieldName: `${TDOC_TYPE}Hours`
  },
  {
    condition: fieldName === `${docType}TaskHours`,
    fieldName: `${TDOC_TYPE}Hours`
  },
  {
    condition: fieldName === `${docType}ResourceHours`,
    fieldName: `${RDOC_TYPE}Total`
  }
].find(({ condition }) => condition)?.fieldName ?? fieldName

export default (props) => {
  const { docsConfig, docType, fieldsType, selectedItem, group: calculationsGroupType } = props;
  const pageProps = useSelector(state => state.ThemeOptions.pageProps);
  const scenarioKeys = (pageProps?.Scenarios || []).map(scenario => {
    const [keyScenario] = Object.entries(scenario).shift();

    return keyScenario;
  });


  const fields = (selectedItem?.[fieldsType] || [])
    .filter(({ field = '', label = '', Group, displayController, value }) =>
        displayController === DISPLAY_CONTROLLERS.GroupedNumber &&
        !!value &&
        Object.keys(value).length && (
          Group === calculationsGroupType ||
          !Group && field.includes(calculationsGroupType) ||
          !Group && !field.includes(calculationsGroupType) && label.includes(calculationsGroupType) ||
          !Group && !getGroupByFieldName(field) && !field.includes(calculationsGroupType) && !label.includes(calculationsGroupType) && calculationsGroupType === CALCULATIONS_GROUP_ESTIMATED
        )
    )
    // .map(({ value }) => value)
  ;

  const calculations = fields.filter(({ field }) => {
    const { Function } = getFieldParams(docsConfig[docType], fieldsType, field) || {};

    return !!Function
  });

  const calculationsSum = fields.filter(({ field }) => {
    const { Function } = getFieldParams(docsConfig[docType], fieldsType, field) || {};

    return !Function
  });

  const unitOfMeasure = Object.keys([...calculations].shift()?.value || {});
  const colspan = unitOfMeasure.length;

  // console.log('calculationsSum: ', calculationsSum)
  const unitOfMeasureSum = Object.keys([...calculationsSum].shift()?.value || {});


  // if (!colspan || !calculations.length) return <></>;

  // console.log('unitOfMeasure: ', unitOfMeasure)
  // console.log('colspan: ', colspan)
  console.log('fields: ', fields)
  const calcFields = (calculations || []).filter(({ field }) => (pageProps.Scenarios || []).every(scenario => {
    const [key] = Object.entries(scenario).shift()

    return !field.includes(key)
  })).map(({ field, label }) => ({
    fieldTitle: field.replace(docType, '').replace(calculationsGroupType, ''),
    label
  }));

  console.log('calcFields: ', calcFields)
  // console.log('calculationsSum: ', calculationsSum)

  const transientProps = {
    $additionalStyles: {
      '& th, td': {
        padding: '10px 5px !important'
      },
    }
  };

  return (
    <div>
      {
        !!calculations.length && (
          <Table sx={{ minWidth: 1024, width: 'auto' }}
               className="table table-hover text-nowrap mt-1" {...transientProps}>
          <thead>
          <tr>
            <th></th>
            {
              [{ base: 'Base' }, ...(pageProps?.Scenarios || [])].map((scenario, index) => {
                const [_, titleScenario] = Object.entries(scenario).shift();

                return (<th key={`${index}-th`} className="text-center" colSpan={colspan}>{titleScenario}</th>)
              })
            }
          </tr>
          <tr>
            <th className="w-150px"></th>
            {
              [{ base: 'Base' }, ...(pageProps?.Scenarios || [])].map((scenario, index) => {

                return unitOfMeasure.map((unit, index) =>
                  (<th key={`${index}-th`} className="w-175px text-center border-1">{unit}</th>)
                )
              })
            }
          </tr>
          </thead>
          <tbody className={classes.root}>
          {
            calcFields.map(({ fieldTitle, label }, index) => (
              <tr key={index}>
                <td className="font-weight-bold text-left">{label || fieldTitle}</td>
                {
                  [{ base: 'Base' }, ...(pageProps?.Scenarios || [])].map((scenario, index) => {
                    const [keyScenario] = Object.entries(scenario).shift();
                    const field = calculations.find(({ field }) => {
                      return keyScenario === 'base'
                        ? field.includes(fieldTitle) && scenarioKeys.every(sKey => !field.includes(sKey))
                        : field.includes(keyScenario) && field.includes(fieldTitle)
                        ;
                    });
                    return unitOfMeasure
                      .filter((unit) => !!field?.field)
                      .map((unit, index) => {
                        const { composeUnit = () => unit } = [
                          {
                            condition: field?.field && field?.field.includes('IRR'),
                            composeUnit: () => UOM_FORMATS.PERCENT
                          },
                          {
                            condition: field?.field && field?.field.includes('PayBack'),
                            composeUnit: () => UOM_FORMATS.DECIMAL
                          }
                        ].find(({ condition }) => condition) || {}

                        return (
                          <td key={`${index}-th`} className="text-center">
                            {
                              !!field?.value?.[unit] &&
                              // (
                              //   !field.field.includes('IRR') && !field.field.includes('NPV') ||
                              //   ((field.field.includes('IRR') || field.field.includes('NPV')) && unit === UOM_FORMATS.CURRENCY)
                              // ) &&
                              uomFormat(composeUnit(), field.value[unit])
                            }
                          </td>)
                      })
                  })
                }
              </tr>
            ))
          }
          </tbody>
        </Table>
        )
      }
      <Table sx={{ width: 'auto' }} className="table table-hover text-nowrap mt-1" {...transientProps}>
        <thead>
        <tr>
          <th className="w-150px"></th>
          {
            unitOfMeasureSum.map((unit, index) =>
              (<th key={`${index}-th`} className="w-175px text-center border-1 " >{unit}</th>)
            )
          }
        </tr>
        </thead>
        <tbody className={classes.root}>
          {
            calculationsSum.map(({ field, label, value }, index) => (
              <tr key={index}>
                <td className="font-weight-bold text-left">{ label }</td>
                {
                  unitOfMeasureSum.map((unit, index) => {
                    console.log('value=: ', value)
                    console.log('unit=: ', unit)
                    const [_, val] = Object.entries(value?.[unit] || {}).shift() || []

                    return (<td key={`${index}-th`} className="text-center" >{ val }</td>)
                  })
                }
              </tr>
            ))
          }
        </tbody>
      </Table>

    </div>
  );
}
